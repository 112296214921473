import { take, select, put, call } from 'redux-saga/effects'
import addDays from 'date-fns/addDays'
import { GEO_IP_COUNTRY_SET } from '../constants'
import { GeoIpTypes } from '../state/action-types'
import { Response } from '../types'
import { GeoIpCountryResponse } from '../types/geoIp'
import { setCookie } from '../utils/cookie'
import { getGeoIpCountryCode } from './selectors/geoIpSelectors'

const sanctionedCountries = [
  'BI', // Burundi
  'BY', // Belarus
  'CD', // Congo, the Democratic Republic of the
  'CF', // Central African Republic
  'CU', // Cuba
  'IQ', // Iraq
  'IR', // Iran
  'LB', // Lebanon
  'LY', // Libya
  'SD', // Sudan
  'SO', // Somalia
  'SS', // South Sudan
  'SY', // Syrian Arab Republic
  'VE', // Venezuela, Bolivarian Republic of
  'ZW', // Zimbabwe
]

export default function* geoBlockSaga() {
  yield take(GeoIpTypes.GEO_IP_COUNTRY__SUCCESS)

  const geoIpCountry: Response<GeoIpCountryResponse> = yield select(
    getGeoIpCountryCode
  )

  const isoCode = geoIpCountry?.data?.country?.iso_code

  // If there is an error, fail open
  // https://fablegroup.atlassian.net/browse/WEB-652?focusedCommentId=15634
  const blocked = geoIpCountry.error
    ? false
    : sanctionedCountries.includes(isoCode)

  yield put({
    type: GeoIpTypes.GEO_IP_COUNTRY_BLOCKED,
    payload: blocked,
  })

  if (isoCode) {
    // Only check once every 24 hours
    yield call(
      setCookie,
      GEO_IP_COUNTRY_SET,
      // Store content as well as blocked status to set Redux initial state
      JSON.stringify({ geoIpData: geoIpCountry.data, blocked }),
      addDays(new Date(), 1)
    )
  }
}
