/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'

import { AuthActions } from '../actions'
import { AuthTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import {
  get,
  getProviderCredential,
  getProviderMethod,
  firebaseAuth,
  post,
} from '@fable/api'

import {
  applyActionCode as firebaseApplyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  linkWithRedirect,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut as firebaseSignOut,
  updateProfile,
  verifyPasswordResetCode,
  signInWithCustomToken,
} from 'firebase/auth'
import { getCookie, setCookie } from '../../utils/cookie'
import { AUTH_METHODS, lsKeys, NO_USER_TEXT } from '../../constants'
import history from '../../routes/history'
import { routes } from '../../app/store/routes'
import { Club, User } from '@fable/types'
import { captureException, getReferralId } from '../../utils'
import { getParameterByName } from '@fable/utils'
import { AxiosResponse } from 'axios'

type DispatchType = Dispatch<AuthActions>

const actionCodeCookieName = (actionCode: string) => `verified_${actionCode}`

const handleAuthenticatedUser = async (
  dispatch: DispatchType,
  user: any,
  method?: string,
  emailOptIn?: boolean
) => {
  // See authSaga.ts
  dispatch(setAction(AuthTypes.TOKEN__REQUEST, { user, method, emailOptIn }))
}

const handleReferralInvite = () => {
  const { id, key } = getReferralId()

  if (!!id) {
    localStorage.setItem(key, id)
  }
}

// APPLY ACTION CODE
export const applyActionCode =
  (actionCode: string) => (dispatch: DispatchType) => {
    if (getCookie(actionCodeCookieName(actionCode))) {
      dispatch(setAction(AuthTypes.APPLY_ACTION_CODE__SUCCESS, actionCode))
      return
    }
    dispatch(setAction(AuthTypes.APPLY_ACTION_CODE__REQUEST))
    firebaseApplyActionCode(firebaseAuth, actionCode)
      .then(() => {
        setCookie(actionCodeCookieName(actionCode), 1)
        dispatch(setAction(AuthTypes.APPLY_ACTION_CODE__SUCCESS, actionCode))
      })
      .catch((error) => {
        dispatch(setAction(AuthTypes.APPLY_ACTION_CODE__ERROR, error))
      })
  }

// AUTH SUCCESS
export const onAuthSuccess = (user: any) => (dispatch: DispatchType) => {
  dispatch(setAction(AuthTypes.AUTH__SUCCESS, user))
}

// GET INVITE
export const getInvite =
  (inviteId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.GET_INVITE__REQUEST, inviteId))
    try {
      const url = `/invites/${inviteId}`
      const { data } = await get(url)
      dispatch(setAction(AuthTypes.GET_INVITE__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(AuthTypes.GET_INVITE__REQUEST, error))
    }
  }

// POST AUTH CREATE CLUB MEMBERSHIP

// This action creation doesn't actually dispatch, it just returns the action.
export const postAuthCreateClubMembership = (
  club: Club,
  startingFrom?: string,
  inviteId?: string
) =>
  setAction(AuthTypes.POST_AUTH_CREATE_CLUB_MEMBERSHIP, {
    club,
    inviteId,
    startingFrom,
  })

// POST AUTH FOLLOW USER
export const postAuthFollowUser = (uuid: string) =>
  setAction(AuthTypes.POST_AUTH_FOLLOW_USER, { uuid })

// POST AUTH PLAN UPSELL
export const postAuthPlanUpsell = (data: { club: Club }) =>
  setAction(AuthTypes.POST_AUTH_PLAN_UPSELL, data)

// RESET PASSWORD
export const resetUserPassword =
  (actionCode: string, password: string) => (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.RESET_PASSWORD__REQUEST))
    confirmPasswordReset(firebaseAuth, actionCode, password)
      .then(() => {
        dispatch(setAction(AuthTypes.RESET_PASSWORD__SUCCESS))
      })
      .catch((error: Error) => {
        dispatch(setAction(AuthTypes.RESET_PASSWORD__ERROR, error))
      })
  }

// SEND RESET PASSWORD EMAIL
export const sendResetPasswordEmail =
  (email: string) => (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.SEND_RESET_PASSWORD_EMAIL__REQUEST))
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        dispatch(setAction(AuthTypes.SEND_RESET_PASSWORD_EMAIL__SUCCESS))
      })
      .catch((error: Error) => {
        dispatch(setAction(AuthTypes.SEND_RESET_PASSWORD_EMAIL__ERROR, error))
      })
  }

// SEND RESET PASSWORD EMAIL - CLEAR
export const clearSendResetPasswordEmail = () => (dispatch: DispatchType) => {
  dispatch(setAction(AuthTypes.SEND_RESET_PASSWORD_EMAIL__CLEAR))
}

// SIGN IN - EMAIL AND PASSWORD
export const signInWithEmailAndPassword =
  (email: string, password: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.SIGNIN__REQUEST))
    try {
      const { user } = await firebaseSignInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      )
      await handleAuthenticatedUser(dispatch, user)
    } catch (error) {
      dispatch(setAction(AuthTypes.SIGNIN__ERROR, error))
    }
  }

// AUTH WITH PROVIDER
export const authWithProvider =
  (provider: any, isInAppBrowser: boolean) => (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.SIGNIN__REQUEST))
    handleReferralInvite()
    // SSO not working in Instagram
    // https://fable-group.sentry.io/issues/4262985345/?environment=production&project=5525893&query=is%3Aunresolved&referrer=issue-stream&stream_index=3
    // https://github.com/firebase/firebase-js-sdk/issues/4421
    if (isInAppBrowser) {
      signInWithRedirect(firebaseAuth, provider)
    } else {
      signInWithPopup(firebaseAuth, provider)
    }
  }

export const authProviderRedirect =
  (redirect: { path: string; type: 'provider' | 'form' }) =>
  (dispatch: DispatchType) =>
    getRedirectResult(firebaseAuth)
      .then(async (result) => {
        if (!result) return

        const additionalUserInfo = getAdditionalUserInfo(result)
        const { storedId, key: referralKey } = getReferralId()
        if (!!storedId && !additionalUserInfo?.isNewUser) {
          localStorage.removeItem(referralKey)
        }

        const credential = getProviderCredential(result)
        const provider = getProviderMethod(result.providerId || '')
        await handleAuthenticatedUser(dispatch, result.user, provider)

        if (credential && !!redirect) {
          if (redirect.type === 'provider') {
            history.push(redirect.path)
          }
          localStorage.removeItem(lsKeys.postAuthRedirect)
        }
      })
      .catch((e) => {
        console.error('getRedirectResult', e)
        localStorage.removeItem(lsKeys.postAuthRedirect)
      })

// SIGN IN - CLEAR
export const clearSignIn = () => (dispatch: DispatchType) => {
  dispatch(setAction(AuthTypes.SIGNIN__CLEAR))
}

// SIGN OUT
export const signOut =
  (postSignoutLink?: string) => (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.SIGNOUT__REQUEST))

    firebaseSignOut(firebaseAuth)
      .then(() => {
        dispatch(setAction(AuthTypes.SIGNOUT__SUCCESS))
        dispatch(setAction(AuthTypes.AUTH__CLEAR))
        history.push(postSignoutLink || routes.explore)
        window.location.reload()
      })
      .catch((error) => {
        dispatch(setAction(AuthTypes.SIGNOUT__ERROR, error))
      })
  }

// SIGN OUT - CLEAR
export const signOutClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(AuthTypes.SIGNOUT__CLEAR))
}

// SIGN UP - EMAIL PASSWORD
export const signUp =
  (
    email: string,
    password: string,
    displayName: string,
    emailOptIn?: boolean
  ) =>
  async (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.SIGNUP__REQUEST))
    try {
      const { user } = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      )

      if (user) {
        handleReferralInvite()
        await updateProfile(user, { displayName })
        await handleAuthenticatedUser(
          dispatch,
          user,
          AUTH_METHODS.password,
          emailOptIn
        )
      }
    } catch (error) {
      dispatch(setAction(AuthTypes.SIGNUP__ERROR, error))
    }
  }

// SIGN UP - CLEAR
export const clearSignUp = () => (dispatch: DispatchType) => {
  dispatch(setAction(AuthTypes.SIGNUP__CLEAR))
}

// VERIFY ACTION CODE
export const verifyActionCode =
  (actionCode: string) => (dispatch: DispatchType) => {
    dispatch(setAction(AuthTypes.VERIFY_ACTION_CODE__REQUEST))
    verifyPasswordResetCode(firebaseAuth, actionCode)
      .then(() => {
        dispatch(setAction(AuthTypes.VERIFY_ACTION_CODE__SUCCESS, actionCode))
      })
      .catch((error) => {
        dispatch(setAction(AuthTypes.VERIFY_ACTION_CODE__ERROR, error))
      })
  }

interface CustomToken {
  custom_token: string
}

type CustomTokenResponse = AxiosResponse<CustomToken>

const getCustomToken = async (auth_token: string) => {
  const customToken = (await post('/auth/custom_token', {
    auth_token,
  })) as CustomTokenResponse
  return customToken.data.custom_token
}

const authWithCustomToken = async (token: string) =>
  await signInWithCustomToken(firebaseAuth, token)

// VERIFY
export const verifyAuth = () => (dispatch: DispatchType) => {
  dispatch(setAction(AuthTypes.VERIFY_USER__REQUEST))

  onAuthStateChanged(firebaseAuth, async (user) => {
    if (user) {
      await handleAuthenticatedUser(dispatch, user) // authSage will set VERIFY_USER__SUCCESS
    } else {
      const authToken = getParameterByName('authToken')

      if (authToken) {
        try {
          const customToken = await getCustomToken(authToken)
          await authWithCustomToken(customToken)
          return
        } catch (error) {
          captureException(error)
        }
      }

      const noUserError = new Error()
      noUserError.message = NO_USER_TEXT

      dispatch(setAction(AuthTypes.AUTH__CLEAR))
      dispatch(setAction(AuthTypes.SIGNIN__ERROR, noUserError))
      dispatch(setAction(AuthTypes.VERIFY_USER__SUCCESS))
    }
  })
}

export const updateAuthUser =
  (updatedUser: Partial<User>) =>
  (dispatch: DispatchType, getState: () => any) => {
    const user = getState().auth?.user
    const updatedUserData = { ...user, ...updatedUser }
    dispatch(setAction(AuthTypes.UPDATE_AUTH_USER, updatedUserData))
  }

window.linkToMicrosoft = () => {
  const auth = getAuth()
  if (auth.currentUser) {
    linkWithRedirect(auth.currentUser, new OAuthProvider('microsoft.com'))
  } else {
    console.error('You must login first')
  }
}
