import { book } from './book'
import { club } from './club'
import { downloads } from './downloads'
import { helpers } from './helpers'
import { posts } from './posts'
import { quote } from './quote'
import { review } from './review'
import { booklist } from './booklist'

export const requests = {
  helpers,
  ...book,
  ...booklist,
  ...club,
  ...downloads,
  ...posts,
  ...quote,
  ...review,
}
