import Head, { HeadProps } from './head/Head'

interface Props extends React.HTMLProps<HTMLDivElement> {
  head: HeadProps | null
  children: React.ReactChild | React.ReactChild[]
}

export const Page: React.VFC<Props> = ({ children, head, ...nativeProps }) => {
  return (
    <div {...nativeProps}>
      <Head {...head} />
      {children}
    </div>
  )
}
