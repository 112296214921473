import { routes as socialRoutes } from '../app/social/routes'
import { routes as clubRoutes } from '../app/clubs/routes'
import { getParameterByName } from '.'
import { referralIdParamKey, referralStorageKey } from '../app/social/constants'

export const getReferralId = () => {
  const { pathname } = window.location
  const invitePage =
    pathname === socialRoutes.invite || pathname === `${socialRoutes.invite}/`
  const clubPage = pathname.includes(clubRoutes.club)
  const dragonQuiz = pathname.includes(socialRoutes.dragonQuiz)
  const validPage = invitePage || clubPage || dragonQuiz
  const referralId = getParameterByName(referralIdParamKey)
  const id = validPage && !!referralId ? referralId : null
  const key = referralStorageKey
  const storedId = localStorage.getItem(key)

  return { id, storedId, key }
}
