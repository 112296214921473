import { FeatureFlagConfig } from './types'

export const featureFlagConfig: FeatureFlagConfig[] = [
  {
    name: 'bdp_list_cro_test',
    splitTest: '10_2023_Web_BDP_List_CRO_Test',
    source: 'server',
    enabled: true,
  },
  {
    name: 'chat',
    source: 'client',
    enabled: false,
  },
  {
    name: 'chatBot',
    source: 'server',
    enabled: true,
    access: 'staging',
  },
  {
    name: 'vertExp',
    source: 'client',
    enabled: true,
    access: 'open',
  },
  {
    name: 'wvDemo',
    source: 'client',
    enabled: true,
  },
]
