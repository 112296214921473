/* eslint require-yield: 0 */
import { select, takeEvery, take } from 'redux-saga/effects'
import {
  AuthTypes,
  BookTypes,
  BillingTypes,
  ClubTypes,
  UserTypes,
} from '../state/action-types'
import { roundCurrency } from '../utils/number'
import { getUserSignIn } from './selectors/authSelectors'
import { AUTH_METHODS, ENVIRONMENT } from '../constants'
import { referralStorageKey } from '../app/social/constants'
import { analytics } from 'segment_analytics'
import { getClubType } from 'app/clubs/utils'

const PRODUCT_CATEGORY = {
  book: 'book',
  club_premium: 'club_premium',
  club_career: 'club_career',
  all_access: 'all_access',
}

function clubJoinedEvent(club, startingFrom) {
  const clubType = getClubType({ club, checkParam: true })

  return {
    event: 'club_joined',
    properties: {
      platform: 'web',
      club_type: clubType,
      is_enterprise: !!club?.company,
      is_open: club?.access_type === 'open',
      club_id: club?.id,
      starting_from: startingFrom,
    },
  }
}

function bookToProduct(book) {
  return {
    product_id: book.id,
    sku: book.isbn,
    name: `Book: ${book.title}`,
    price: book.price_usd,
    category: PRODUCT_CATEGORY.book,
  }
}

function planToProduct(plan) {
  const isOneTime = plan.type === 'one_time'
  const isClub = !!plan.club
  return {
    product_id: plan.id,
    sku: plan.id,
    name: isClub ? `Club: ${plan.club.title}` : plan.title,
    price: plan.amount,
    category: isClub
      ? isOneTime
        ? PRODUCT_CATEGORY.club_career
        : PRODUCT_CATEGORY.club_premium
      : PRODUCT_CATEGORY.all_access,
  }
}

function bundleToProduct(bundle) {
  return {
    product_id: bundle.price.id,
    sku: bundle.price.id,
    name: `Club: ${bundle.name}`,
    price: bundle.price.amount,
    category: PRODUCT_CATEGORY.club_career,
  }
}

function checkoutItemsToOrder(checkoutItems, orderProps = {}) {
  const products = []
  if (checkoutItems.cart) {
    checkoutItems.cart.forEach((item) => {
      products.push(bookToProduct(item.product))
    })
  }
  if (checkoutItems.book) {
    products.push(bookToProduct(checkoutItems.book))
  }
  if (checkoutItems.plan) {
    products.push(planToProduct(checkoutItems.plan))
  }
  if (checkoutItems.bundle) {
    products.push(bundleToProduct(checkoutItems.bundle))
  }
  return {
    ...orderProps,
    products: products,
    revenue: roundCurrency(
      products.reduce((acc, product) => acc + parseFloat(product.price), 0.0)
    ),
  }
}

const trackables = {
  [AuthTypes.SIGNOUT__SUCCESS]: function* () {
    analytics.reset()
    return [
      {
        event: 'user_logout',
      },
    ]
  },
  [AuthTypes.SIGNUP__SUCCESS]: function* ({
    method,
    club = null,
    referralId = null,
  }) {
    const clubType = getClubType({ club, checkParam: true })

    const params = {
      event: 'user_signup',
      properties: {
        platform: 'web',
        auth_method: method,
        is_referral: false,
      },
    }

    if (!!clubType) {
      params.properties.club_type = clubType
    }

    if (!!referralId) {
      params.properties.is_referral = true
      localStorage.removeItem(referralStorageKey)
    }

    return [params]
  },
  [ClubTypes.CREATE_CLUB_MEMBERSHIP__SUCCESS]: function* ({
    club,
    startingFrom,
  }) {
    return [clubJoinedEvent(club, startingFrom)]
  },
  [ClubTypes.TAPPED_CAROUSEL_CLUB_CARD]: function* ({ club }) {
    const clubType = getClubType({ club, checkParam: true })

    return [
      {
        event: 'tapped_club_from_carousel',
        properties: {
          platform: 'web',
          club_type: clubType,
          is_enterprise: !!club?.company,
          is_open: club?.access_type === 'open',
          club_id: club?.id,
          media_type: club?.current_book?.type || 'book',
        },
      },
    ]
  },
  [AuthTypes.TOKEN__SUCCESS]: function* () {
    yield take(UserTypes.GET_USER_PROFILE__SUCCESS)
    const userSignIn = yield select(getUserSignIn)
    const method = userSignIn?.data?.initial_auth_method || ''

    if (!method || method === AUTH_METHODS.token) {
      return []
    }

    return [
      {
        event: 'user_login',
        properties: {
          platform: 'web',
          auth_method: method,
        },
      },
    ]
  },
  [AuthTypes.SIGNIN__ERROR]: function* (error) {
    if (error?.message === 'There is no current user') {
      return []
    }
    return [
      {
        event: 'user_login_error',
        properties: { error },
      },
    ]
  },
  // THIS THIS THIS
  [BookTypes.MODIFY_BOOK_LIST__SUCCESS]: function* ({ book }) {
    return [
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-added-to-wishlist
      {
        event: 'Product Added to Wishlist',
        properties: bookToProduct(book),
      },
    ]
  },
  [BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__SUCCESS]: function* ({ book }) {
    return [
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed-from-wishlist
      {
        event: 'Product Removed from Wishlist',
        properties: bookToProduct(book),
      },
    ]
  },
  [BookTypes.BOOK_DETAIL__VIEWED]: function* ({ book }) {
    return [
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-viewed
      {
        event: 'Product Viewed',
        properties: bookToProduct(book),
      },
    ]
  },
  [BillingTypes.CHECKOUT__START]: function* ({ checkoutItems, properties }) {
    return [
      // https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
      {
        event: 'Checkout Started',
        properties: checkoutItemsToOrder(checkoutItems, properties),
      },
    ]
  },
  [BillingTypes.CREATE_ORDER__SUCCESS]: function* ({ checkoutItems }) {
    return [
      // https://segment.com/docs/connections/spec/ecommerce/v2/#payment-info-entered
      {
        event: 'Payment Info Entered',
        properties: checkoutItemsToOrder(checkoutItems),
      },
    ]
  },
  [BillingTypes.CHECKOUT__COMPLETE]: function* ({
    checkoutItems,
    invoice,
    startingFrom,
  }) {
    const events = [
      // https://segment.com/docs/connections/spec/ecommerce/v2/#order-completed
      {
        event: 'Order Completed',
        properties: checkoutItemsToOrder(checkoutItems, {
          order_id: invoice.id,
        }),
      },
    ]

    if (checkoutItems?.bundle) {
      const productList = checkoutItems?.bundle?.product_list
      for (const item of productList) {
        if (item.product_type === 'Club' && item.is_primary) {
          events.push(clubJoinedEvent(item.product_object, startingFrom))
        }
      }
    }

    if (checkoutItems?.plan && checkoutItems?.plan?.club) {
      events.push(clubJoinedEvent(checkoutItems.plan.club, startingFrom))
    }

    if (checkoutItems?.plan && checkoutItems.plan.type !== 'one_time') {
      events.push({
        event: 'user_subscribed',
        properties: {
          amount: checkoutItems.plan.amount,
          interval: checkoutItems.plan.interval,
        },
      })
    }

    return events
  },
  [AuthTypes.APPLY_ACTION_CODE__SUCCESS]: function* () {
    return [
      {
        event: 'email_verified',
        properties: {},
      },
    ]
  },
}

const conversionHandlers = {
  'Order Completed': (eventResult) => ({
    conversion_id: 8641121,
    conversion_value: eventResult.revenue,
  }),
  user_signup: () => ({ conversion_id: 8641113 }),
}

function* handleEvent(action) {
  try {
    const results = yield* trackables[action.type](action.payload || {})
    if (results) {
      for (const result of results) {
        if (result) {
          analytics.track(result.event, result.properties)
          if (ENVIRONMENT !== 'production') {
            continue
          }
          const conversionHandler = conversionHandlers[result.event]
          if (conversionHandler) {
            const conversion = conversionHandler(result.properties)
            analytics.track_conversion(conversion)
          }
        }
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export default function* analyticsSaga() {
  yield takeEvery(Object.keys(trackables), handleEvent)
}
