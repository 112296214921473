import { useEffect, useState } from 'react'

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isInAppBrowser, setIsInAppBrowser] = useState(false)

  // check if device is mobile
  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent

    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    const isIpad = !!(
      userAgent.match('Macintosh') && navigator.maxTouchPoints > 0
    )

    const inAppBrowser = !!userAgent.match(
      /Instagram|BytedanceWebview|TikTok|FBAN|FBAV|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv)/i
    )

    setIsMobile(mobile || isIpad)
    setIsInAppBrowser(inAppBrowser)
  }, [])

  return { isMobile, isInAppBrowser }
}

export default useDeviceDetect
