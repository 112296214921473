import { Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { useDeviceDetect } from '@fable/hooks'
import { Page } from '../../../../components/Page'
import PageHeroShapes from '../../../../components/PageHeroShapes'
import welcomeArt from '../../../../assets/images/graphics/art-Welcome.webp'
import { InviteWelcomePageLinks } from './InviteWelcomePageLinks'
import { SharePageQR } from '../share_page/SharePageQR'

const InviteWelcomePage = () => {
  const { mediaQueries } = useTheme()
  const { isMobile } = useDeviceDetect()

  return (
    <Page head={{ title: 'Welcome to the Fable community!' }}>
      <PageHeroShapes
        className={css`
          padding: 148px 16px 100px;
          ${mediaQueries.mobileL} {
            padding-top: 88px;
          }
        `}
        hideDivider
      >
        <img
          className={css`
            margin: 0 auto;
            display: block;
            width: 108px;
            ${mediaQueries.mobileL} {
              width: 168px;
            }
          `}
          src={welcomeArt}
          alt="welcome illustration"
        />
        <Text
          className={css`
            margin: 18px 0 14px;
          `}
          type="header"
          sizing={{ base: 'S', mobileL: 'L' }}
          align="center"
        >
          Welcome to the
          <br />
          Fable community!
        </Text>
        <Text
          className={css`
            margin: 0 auto;
            max-width: 280px;
            ${mediaQueries.mobileL} {
              max-width: 460px;
            }
          `}
          type="body"
          sizing={{ base: 'M', mobileL: 'L' }}
          align="center"
        >
          <span
            className={css`
              display: none;
              ${mediaQueries.mobileL} {
                display: block;
              }
            `}
          >
            Download the app to claim your $5 credit. Enter your phone number
            and we'll text you a link to make things easy.
          </span>
          <span
            className={css`
              ${mediaQueries.mobileL} {
                display: none;
              }
            `}
          >
            Download the app to claim your $5 credit to buy an ebook or a
            premium membership.
          </span>
        </Text>
        <div
          className={css`
            margin-top: 20px;
          `}
        >
          {isMobile ? (
            <InviteWelcomePageLinks />
          ) : (
            <SharePageQR
              className={css`
                margin: 0 auto;
                z-index: 0;
              `}
              hideOnMobileLayout={false}
            />
          )}
        </div>
      </PageHeroShapes>
    </Page>
  )
}

export default InviteWelcomePage
